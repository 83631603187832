import { render, staticRenderFns } from "./ChartPie.vue?vue&type=template&id=4dda9d64"
import script from "./ChartPie.vue?vue&type=script&setup=true&lang=ts"
export * from "./ChartPie.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ChartPie.vue?vue&type=style&index=0&id=4dda9d64&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,LegendItem: require('/app/components/Chart/components/LegendItem.vue').default,Legend: require('/app/components/Chart/components/Legend.vue').default,Layout: require('/app/components/Chart/components/Layout.vue').default})
